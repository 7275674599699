import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHttpClient } from "../hooks/http-hook";
import { toast } from "react-toastify";

const AgentModal = ({ show, onHide, language }) => {
  const [formStep, setFormStep] = useState(1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [password, setPassword] = useState("");
  const { sendRequest } = useHttpClient();
  const notify = (type, msg) => {
    type === "err"
      ? toast.error(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      : toast.success(`${msg}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  };
  const [universities, setUniversities] = useState([
    {
      id: 1,
      country: "",
      university: "",
      campus: "",
      course: "",
      intake: "",
      year: "",
    },
  ]);
  const [fields, setFields] = useState([
    { id: 1, title: "", type: "Text", attachments: [] },
    { id: 2, title: "", type: "Text", attachments: [] },
    { id: 3, title: "", type: "Text", attachments: [] },
  ]);

  const handleAddUniversity = () => {
    setUniversities([
      ...universities,
      {
        id: universities.length + 1,
        country: "",
        university: "",
        campus: "",
        course: "",
        intake: "",
        year: "",
      },
    ]);
  };

  const handleUniversityChange = (id, key, value) => {
    setUniversities(
      universities.map((uni) =>
        uni.id === id ? { ...uni, [key]: value } : uni
      )
    );
  };

  const handleRemoveUniversity = (id) => {
    setUniversities(universities.filter((uni) => uni.id !== id));
  };

  const handleNextStep = () => {
    setFormStep(2);
  };

  const handleBackStep = () => {
    setFormStep(1);
  };

  const handleAddField = () => {
    setFields([
      ...fields,
      { id: fields.length + 1, title: "", type: "Text", attachments: [] },
    ]);
  };

  const handleFieldChange = (id, key, value) => {
    if (key === "subFields") {
      setFields(
        fields.map((field) =>
          field.id === id ? { ...field, [key]: value } : field
        )
      );
    } else {
      setFields(
        fields.map((field) =>
          field.id === id ? { ...field, [key]: value } : field
        )
      );
    }
  };

  const handleRemoveField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const formatData = () => {
    const formattedUniversities = universities.map((uni) => ({
      name: uni.university,
      country: uni.country,
      campus: uni.campus,
      course: uni.course,
      session: uni.intake,
      year: uni.year,
    }));

    const formattedFields = fields.map((field) => ({
      field_name: field.title,
      field_type: field.type ? field.type : "Text",
      children: field.subFields
        ? field.subFields.map((subField) => ({
            field_name: subField.title,
            field_type: subField.type ? subField.type : "Text",
          }))
        : [],
    }));

    const dataToSend = {
      password: password ? password : null,
      universities: formattedUniversities,
      form_builder: formattedFields,
    };

    return dataToSend;
  };

  const updateUser = async () => {
    setConfirmModal(false);

    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user/update_info",
        "POST",
        JSON.stringify(formatData()),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success === "yes") {
        setPassword("");
        onHide();
        notify("success", "Profile updated successfully!");
      } else throw new Error("Something Went Wrong!");
    } catch (err) {
      notify("err", err.message);
    }
  };

  const modalStyles = {
    header: {
      fontFamily: "Nunito",
      fontSize: "20px",
      fontWeight: 700,
      color: "#333",
    },
    body: {
      fontFamily: "Nunito",
      fontSize: "16px",
      color: "#666",
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  const inputStyle = {
    height: "50px",
    padding: "8px",
    margin: "0",
    borderRadius: "4px",
    background: "var(--WhiteBG, #FFF)",
    boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.10)",
    cursor: "pointer",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 24 24'><path fill='none' stroke='gray' stroke-width='2' d='M6 9l6 6 6-6'/></svg>")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 8px center",
    border: "1px solid #CCC",
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton style={modalStyles.header}>
          <Modal.Title>
            {language === "en"
              ? "Request to be an agent"
              : "এজেন্ট হতে অনুরোধ করুন"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyles.body}>
          {formStep === 1 && (
            <>
              <h4>
                {language === "en"
                  ? "University Details"
                  : "বিশ্ববিদ্যালয়ের বিবরণ"}
              </h4>
              {universities.map((uni) => (
                <div
                  key={uni.id}
                  style={{ marginBottom: "20px", position: "relative" }}
                >
                  <button
                    onClick={() => handleRemoveUniversity(uni.id)}
                    style={{
                      position: "absolute",
                      top: "-27px",
                      right: "0",
                      border: "none",
                      background: "none",
                      cursor: "pointer",
                      color: "#D9534F",
                      fontSize: "24px",
                    }}
                  >
                    &times;
                  </button>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={language === "en" ? "Country" : "দেশ"}
                        value={uni.country}
                        onChange={(e) =>
                          handleUniversityChange(
                            uni.id,
                            "country",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={
                          language === "en" ? "University" : "বিশ্ববিদ্যালয়"
                        }
                        value={uni.university}
                        onChange={(e) =>
                          handleUniversityChange(
                            uni.id,
                            "university",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={language === "en" ? "Campus" : "ক্যাম্পাস"}
                        value={uni.campus}
                        onChange={(e) =>
                          handleUniversityChange(
                            uni.id,
                            "campus",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={language === "en" ? "Course" : "কোর্স"}
                        value={uni.course}
                        onChange={(e) =>
                          handleUniversityChange(
                            uni.id,
                            "course",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={
                          language === "en"
                            ? "Intake/Session/Semester"
                            : "ইনটেক/সেশন/সেমিস্টার"
                        }
                        value={uni.intake}
                        onChange={(e) =>
                          handleUniversityChange(
                            uni.id,
                            "intake",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                    <div style={{ flex: "1 1 45%" }}>
                      <input
                        type="text"
                        placeholder={language === "en" ? "Year" : "বছর"}
                        value={uni.year}
                        onChange={(e) =>
                          handleUniversityChange(uni.id, "year", e.target.value)
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #CCC",
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button
                variant="outline-primary"
                onClick={handleAddUniversity}
                style={{ marginBottom: "20px", borderRadius: "4px" }}
              >
                {language === "en"
                  ? "Add Another University"
                  : "আরো একটি বিশ্ববিদ্যালয় যুক্ত করুন"}
              </Button>
              <div style={modalStyles.footer}>
                <Button variant="primary" onClick={handleNextStep}>
                  {language === "en" ? "Next" : "পরবর্তী"}
                </Button>
              </div>
            </>
          )}

          {formStep === 2 && (
            <>
              <h4>
                {language === "en"
                  ? "Additional Fields"
                  : "অতিরিক্ত ক্ষেত্রসমূহ"}
              </h4>
              {fields.map((field) => (
                <div key={field.id}>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "20px",
                      gap: "10px",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <input
                      type="text"
                      placeholder={
                        language === "en" ? "Field Title" : "ক্ষেত্রের শিরোনাম"
                      }
                      value={field.title}
                      onChange={(e) =>
                        handleFieldChange(field.id, "title", e.target.value)
                      }
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid #CCC",
                      }}
                    />
                    <select
                      value={field.type}
                      onChange={(e) =>
                        handleFieldChange(field.id, "type", e.target.value)
                      }
                      style={inputStyle}
                    >
                      <option value="Text">Text</option>
                      <option value="Number">Number</option>
                      <option value="Composite">Composite</option>
                      <option value="Single Attachment">
                        Single Attachment
                      </option>
                      <option value="Multiple Attachments">
                        Multiple Attachments
                      </option>
                    </select>
                    <button
                      onClick={() => handleRemoveField(field.id)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        color: "#D9534F",
                        fontSize: "24px",
                      }}
                    >
                      &times;
                    </button>
                  </div>

                  {field.type === "Composite" && (
                    <>
                      <h5 style={{ marginTop: "-10px" }}>
                        {language === "en" ? "Sub-fields" : "উপ-ক্ষেত্রসমূহ"}
                      </h5>
                      {field.subFields?.map((subField, index) => (
                        <div
                          style={{
                            paddingLeft: "10px",
                          }}
                        >
                          <div
                            key={index}
                            style={{
                              marginBottom: "10px",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <input
                              type="text"
                              placeholder={
                                language === "en"
                                  ? "Sub-field Title"
                                  : "উপ-ক্ষেত্রের শিরোনাম"
                              }
                              value={subField.title}
                              onChange={(e) =>
                                handleFieldChange(field.id, "subFields", [
                                  ...field.subFields.slice(0, index),
                                  { ...subField, title: e.target.value },
                                  ...field.subFields.slice(index + 1),
                                ])
                              }
                              style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #CCC",
                              }}
                            />
                            <select
                              value={subField.type}
                              onChange={(e) => {
                                const updatedSubFields = field.subFields.map(
                                  (sf, idx) =>
                                    idx === index
                                      ? { ...sf, type: e.target.value }
                                      : sf
                                );
                                handleFieldChange(
                                  field.id,
                                  "subFields",
                                  updatedSubFields
                                );
                              }}
                              style={inputStyle}
                            >
                              <option value="Text">Text</option>
                              <option value="Number">Number</option>
                              <option value="Single Attachment">
                                Single Attachment
                              </option>
                              <option value="Multiple Attachments">
                                Multiple Attachments
                              </option>
                            </select>
                            <button
                              onClick={() => handleRemoveField(field.id)}
                              style={{
                                border: "none",
                                background: "none",
                                cursor: "pointer",
                                color: "#D9534F",
                                fontSize: "24px",
                              }}
                            >
                              &times;
                            </button>
                          </div>
                        </div>
                      ))}
                      <Button
                        variant="outline-primary"
                        onClick={() =>
                          handleFieldChange(field.id, "subFields", [
                            ...(field.subFields || []),
                            "",
                          ])
                        }
                        style={{
                          marginBottom: "20px",
                          borderRadius: "4px",
                        }}
                      >
                        {language === "en"
                          ? "Add Sub-field"
                          : "উপ-ক্ষেত্র যুক্ত করুন"}
                      </Button>
                    </>
                  )}
                </div>
              ))}
              <Button
                variant="primary"
                onClick={handleAddField}
                style={{ marginBottom: "20px", borderRadius: "4px" }}
              >
                {language === "en"
                  ? "Add Another Field"
                  : "আরো একটি ক্ষেত্র যুক্ত করুন"}
              </Button>
              <div style={modalStyles.footer}>
                <Button variant="outline-secondary" onClick={handleBackStep}>
                  {language === "en" ? "Back" : "পিছনে"}
                </Button>
                <Button variant="primary" onClick={() => setConfirmModal(true)}>
                  {language === "en" ? "Request Approval" : "অনুরোধ অনুমোদন"}
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className="form-section mb-0">
            {language === "en"
              ? "Enter Current Password To Update Changes"
              : "পরিবর্তন আপডেট করতে বর্তমান পাসওয়ার্ড লিখুন"}
            <input
              type="password"
              required
              className="form-control mb-0"
              placeholder={
                language === "en"
                  ? "Enter your password"
                  : "আপনার পাসওয়ার্ড লিখুন"
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-secondary"
            onClick={() => setConfirmModal(false)}
          >
            {language === "en" ? "Close" : "বন্ধ"}
          </Button>
          <Button variant="primary" type="button" onClick={updateUser}>
            {language === "en" ? "Save Changes" : "পরিবর্তনগুলোর সংরক্ষন"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AgentModal;
