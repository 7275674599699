import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AdminLayout from "../../components/AdminLayout";
import AdminTableView from "../../components/AdminLayout/AdminComponentView/AdminTableView";
import AdminResetPasswordModal from "../../components/modals/AdminResetPasswordModal/AdminResetPasswordModal";
import Button from "../../components/shared/Button/Button";
import Dropdown from "../../components/shared/Dropdown/Dropdown";
import axios from "axios";
import { GET_USERS } from "../../lib/api/admin";
import Modal from "../../components/shared/Modal/Modal";
import { useHttpClient } from "../../hooks/http-hook";

export default function ManageAgents() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchUser, setSearchUser] = useState(null);
  const [dataModal, setDataModal] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [user, setUser] = useState(null);

  const [sort, setSort] = useState({ fullname: "asc" });

  const { sendRequest } = useHttpClient();

  const fetchUser = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/admin/get_agent_request"
      );
      setUsersData(responseData);
      console.log(responseData.data);
    } catch (err) {}
  };

  const approveOrRejectUser = async (data) => {
    try {
      const newStatus = data.status === "Pending" ? true : false;

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/set_agent_status`,
        "PUT",
        JSON.stringify({
          id: data._id,
          agent_status: newStatus,
        }),
        { "Content-Type": "application/json" }
      );

      toast.success(`User status has been updated to ${newStatus.toString()}`);

      fetchUser();
    } catch (err) {
      toast.error(err.message || "Failed to update status.");
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {/* Display University and formBuilder information in the table */}
      {/* <Modal
        show={dataModal}
        onHide={() => setDataModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>University Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5>University Information</h5>
            <p>
              University Name: {usersData?.data?.data[0]?.universities[0]?.name}
            </p>
            <p>Country: {usersData?.data?.data[0]?.universities[0]?.country}</p>
            <h5>Form Builder Information</h5>
            <p>Form Builder ID: {usersData?.data?.data[0]?.formBuilderId}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setDataModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal> */}
      <AdminLayout>
        <h3 style={{ fontWeight: "bold" }}>Manage Agents</h3>

        <AdminTableView
          //   query={{ status, error }}
          search={{
            title: "Search User",
            placeholder: "Search user by name, email...",
            btnText: "Search User",
            onSearch: (value) => setSearch(value),
          }}
          sort={{
            title: "Sort By",
            selects: [
              {
                name: "Sort By",
                options: [
                  { label: "Name (ASC)", value: "fullname:1" },
                  { label: "Name (DESC)", value: "fullname:-1" },
                  { label: "Email (ASC)", value: "email:1" },
                  { label: "Email (DESC)", value: "email:-1" },
                  { label: "Country (ASC)", value: "universities.country:1" },
                  { label: "Country (DESC)", value: "universities.country:-1" },
                  { label: "University (ASC)", value: "universities.name:1" },
                  { label: "University (DESC)", value: "universities.name:-1" },
                  { label: "Status (Approved)", value: "approved_agent:1" },
                  { label: "Status (Pending)", value: "approved_agent:-1" },
                ],
                onSort: ({ target }) => {
                  const [key, value] = target?.value?.split(":");
                  setSort(() => ({ [key]: +value }));
                },
              },
            ],
          }}
          table={{
            headers: [
              { name: "Name", dotsMenu: [], key: "name" },
              { name: "Email Address", dotsMenu: [], key: "email" },
              { name: "Country", dotsMenu: [], key: "country" },
              { name: "Universities", dotsMenu: [], key: "universities" },
              { name: "Status", dotsMenu: [], key: "status" },
              {
                name: "Actions",
                dotsMenu: [
                  {
                    name: "Toggle Status",
                    onClick: (data) => {
                      approveOrRejectUser(data);
                    },
                  },
                  {
                    name: "View Form",
                    onClick: (data) => {
                      setUser(data);
                      console.log(data);
                    },
                  },
                  {
                    name: "View Profile",
                    onClick: (data) => history.push(`/profile/${data?._id}`),
                  },
                ],
                key: "actions",
              },
            ],
            data: usersData?.data?.map((u) => ({
              _id: u._id || "",
              name: u?.fullname || "N/A",
              email: u?.email || "N/A",
              country: u?.universities[0]?.country || "N/A",
              universities: u?.universities[0]?.name || "N/A",
              status: u?.approved_agent === true ? "Approved" : "Pending",
              actions: "",
            })),
          }}
        />
      </AdminLayout>
    </>
  );
}
