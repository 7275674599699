import "bootstrap/dist/css/bootstrap.min.css";
import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import io from "socket.io-client";
import { useHttpClient } from "../src/hooks/http-hook";
import Logout from './pages/logout';
import "./App.css";
import DealWithProviderDetails from "./components/DealWithProviderDetails/DealWithProviderDetails";
import Page from "./components/shared/Page/Page";
import ErrorModal from "./components/util/ErrorModal";
import { AuthContext } from "./context/auth-context";
import Admin from "./pages/admin";
import AddPromotions from "./pages/admin/AddPromotions/AddPromotions";
import ClientPayments from "./pages/admin/DuePayment/ClientPayments";
import DuePayment from "./pages/admin/DuePayment/DuePayment";
import FailedBids from "./pages/admin/FailedBids/FailedBids";
import FailedDeals from "./pages/admin/FailedDeals/FailedDeals";
import Feedbacks from "./pages/admin/Feedbacks/Feedbacks";
import GlobalSettingsNew from "./pages/admin/GlobalSettings/GlobalSettings";
import JobCategories from "./pages/admin/JobCategories/JobCategories";
import ManageNewAdmins from "./pages/admin/ManageAdmins/ManageAdmins";
import AllReferrals from "./pages/admin/Referrals/Referrals";
import StaticPages from "./pages/admin/StaticPages/StaticPages";
import SystemLogs from "./pages/admin/SystemLogs/SystemLogs";
import SystemReportNew from "./pages/admin/SystemReport/SystemReport";
import UiConfigurations from "./pages/admin/UiConfigurations/UiConfigurations";
import UnapprovedJobs from "./pages/admin/UnapprovedJobs/UnapprovedJobs";
import UnresolvedIssuesNew from "./pages/admin/UnresolvedIssues/UnresolvedIssues";
import Users from "./pages/admin/Users/Users";
import AdminDisapproved from "./pages/AdminDisapproved";
import AdminFaildeDeals from "./pages/AdminFaildeDeals";
import AdminFailedBids from "./pages/AdminFailedBids";
import Blocked from './pages/blocked';
import AdminJobs from "./pages/AdminJobs";
import AdminPromo from "./pages/AdminPromo";
import AdminRefundedDeals from "./pages/AdminRefundedDeals";
import ApproveJobNew from "./pages/ApproveJobNew";
import Bid from "./pages/Bid";
import Deal from "./pages/Deal";
import DemoPage from "./pages/DemoPage";
import EditCategories from "./pages/EditCategories";
import EditJob from "./pages/EditJob";
import Explore from "./pages/Explore";
import GetDuePayments from "./pages/GetDuePayments";
import GetSuccessfulPayments from "./pages/GetSuccessfulPayments";
import GlobalSettings from "./pages/GlobalSettings";
import Home from "./pages/Home";
import ImpersonateUser from "./pages/ImpersonateUser";
// import JobDetails from "./pages/JobDetails";
import Login from "./pages/Login";
import ManageAdmins from "./pages/ManageAdmins";
import Messages from "./pages/Messages";
import NotFound from "./pages/NotFound";
import Notifications from "./pages/Notifications";
import PaymentDone from "./pages/PaymentDone";
import PaymentSuccess from "./pages/PaymentSuccess";
import PostDirect from "./pages/PostDirect";
import Profile from "./pages/Profile";
import Providers from "./pages/Providers";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";
import SystemReport from "./pages/SystemReport";
import Tokenize from "./pages/Tokenize";
import TokenizeNew from "./pages/TokenizeNew";
import UiConfiguration from "./pages/UiConfiguration";
import UnresolvedIssues from "./pages/UnresolvedIssues";
import VerifyEmail from "./pages/VerifyEmail";
import OtpVerification from "./pages/OtpPage";
import VerifyForgotPass from "./pages/VerifyForgotPass";
import PrivateRoute from "./PrivateRoute";
import Logs from "./sections/notifications/Logs";
import CreateNewPost from "./uicomponents/CreateNewPost/CreateNewPost";
import ClientPrintDealDetails from "./uicomponents/DealWithClient/ClientPrintDealDetails";
import ClientServiceDealDetails from "./uicomponents/DealWithClient/ClientServiceDealDetails";
import SendFeedBack from "./uicomponents/SendFeedBack/SendFeedBack";
import About from "./uicomponents/userPanel/about/About";
import FAQ from "./uicomponents/userPanel/faq/FAQ";
import JobDetails from "./uicomponents/userPanel/jobDetails/JobDetails";
import JobDetailsNew from "./uicomponents/userPanel/jobDetails/JobDetailsNew";
import PaymentTerms from "./uicomponents/userPanel/paymentTerms/PaymentTerms";
import Privacy from "./uicomponents/userPanel/privacy/Privacy";
import ProfileOther from "./uicomponents/userPanel/ProfileView/ProfileOther";
import Referrals from "./uicomponents/userPanel/referrals/Referrals";
import Terms from "./uicomponents/userPanel/terms/Terms";
import PaymentFailed from "../src/pages/PaymentFailed";
import axios from "axios";
import Unsubscribe from "./pages/unsubscribe";
import ManageAgents from "./pages/admin/ManageAgents";

function App() {
  //states for auth context
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [socket, setSocket] = useState(null);
  const [user, setUser] = useState(null);
  const [language, setLanguage] = useState("bn");
  const [uiConfig, setUiConfig] = useState(null);
  const [categories, setCategories] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const globals = async () => {
    try {
      const res = await axios.get("https://somadha.com/api/ui-config", {
      
      });

      setLanguage(res.data.default_lang);
    } catch (e) {
      //console.log(e);
    }
  };

  useEffect(() => {
    globals();
  }, []);

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);
  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    setIsAdmin(false);
    setIsManager(false);
    setSocket(null);
    localStorage.removeItem("auth_token");
  };

  const setUserData = useCallback(() => {
    if (localStorage.getItem("auth_token")) {
      fetchUser();
    }
    //eslint-disable-next-line
  }, []);

  const onSuccess = () => {
    setRefresh(!refresh);
  };
  const { error, sendRequest, clearError } = useHttpClient();

  const fetchUser = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/user"
      );
      setUser(responseData);
      if (responseData.user_type === "ADMIN") {
        setIsAdmin(true);
      } else setIsAdmin(false);
      if (responseData.user_type === "MANAGER") {
        setIsManager(true);
      } else setIsManager(false);
    } catch (err) {}
  };

  const fetchUiConfig = async () => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/ui-config"
      );
      if (responseData._id) {
        setUiConfig(responseData);
        //change color theme
        Object.keys(responseData.color_theme).map((key) => {
          const value = responseData.color_theme[key];
          document.documentElement.style.setProperty(`--${key}`, value);
        });
        Object.keys(responseData.color_palette).map((key) => {
          const value = responseData.color_palette[key];
          document.documentElement.style.setProperty(`--${key}`, value);
        });
      }
    } catch (err) {}
  };
  const fetchCategories = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/get_parent_categories`
      );
      setCategories(responseData);
    } catch (err) {}
  };

  const setupSocket = () => {
    const token = localStorage.getItem("auth_token");
    //console.log(token);
    if (token && !socket) {
      //For Production, this should be
      const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`, {
        path: "/api/socket.io",
        query: {
          token: token,
        },
      });
      newSocket.on("disconnect", () => {
        setSocket(null);
        setTimeout(setupSocket, 3000);
      });
      newSocket.on("connect", () => {});
      setSocket(newSocket);
    }
  };

  //setup
  useEffect(() => {
    fetchUiConfig();
    fetchCategories();
    const getLanguage = localStorage.getItem("language");
    localStorage.setItem("P_AD", false);
    if (getLanguage) setLanguage(getLanguage);
    else {
      setLanguage("en");
      localStorage.setItem("language", "en");
    }
    const trySetup = () => {
      if (localStorage.getItem("auth_token")) {
        login();
        fetchUser();
        setupSocket();
      } else {
      }
    };
    trySetup();
  }, []);

  const changeLanguage = () => {
    if (language === "en") {
      setLanguage("bn");
      localStorage.setItem("language", "bn");
    }
    if (language === "bn") {
      setLanguage("en");
      localStorage.setItem("language", "en");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isAdmin: isAdmin,
        isManager: isManager,
        refresh: refresh,
        language: language,
        uiConfig: uiConfig,
        user: user,
        categories: categories,
        setRefresh: onSuccess,
        changeLanguage: changeLanguage,
        login: login,
        logout: logout,
        setUser: setUserData,
        refetch: fetchUser,
      }}
    >
      <div
        ref={(node) => {
          if (node) {
            node.style.setProperty(
              "font-family",
              ` ${uiConfig && uiConfig.global_font_en}  , ${
                uiConfig && uiConfig.global_font_bn
              }, Nunito, HelveticaNeue, Helvetica Neue`,
              "important"
            );
          }
        }}
        // style={{
        //   fontFamily: ` ${uiConfig && uiConfig.global_font_en}  , ${
        //     uiConfig && uiConfig.global_font_bn}`,
        // }}
      >
        {/* <NewNavBar></NewNavBar> */}

        <ErrorModal error={error} onClear={clearError} />
        <Router>
          {/* <Navbar socket={socket} /> */}
          <Switch>
            <PrivateRoute path="/user/:option" exact>
              <Page socket={socket}>
                <Profile />
              </Page>
            </PrivateRoute>
            {user?._id && <Redirect from="/signup" to="/" />}

            <Route path="/" exact>
              <Page socket={socket}>
                <Home/>
              </Page>
            </Route>
            <Route path="/tokenize/:token" exact>
              <Page socket={socket}>
                <Tokenize />
              </Page>
            </Route>
            <Route path="/tokenize/new_user/:token" exact>
              <Page socket={socket}>
                <TokenizeNew />
              </Page>
            </Route>
            <Route
              path="/login"
              exact
              render={() => (
                <Page socket={socket}>
                  <Login setupSocket={setupSocket} />
                </Page>
              )}
            />
            <Route path="/sign-up" exact>
              <Page socket={socket}>
                <Signup />
              </Page>
            </Route>
            <Route path="/forgot-password" exact>
              <Page socket={socket}>
                <ResetPassword />
              </Page>
            </Route>
            {/* <Route path="/explore/all" component={Explore} /> */}
            <Route path="/explore/:category/:key?" exact>
              {/* <Route path="/explore/" exact> */}
              <Page socket={socket}>
                <Explore />
              </Page>
            </Route>
            <Route path="/providers/:category" exact>
              <Page socket={socket}>
                <Providers />
              </Page>
            </Route>
            <PrivateRoute path="/post" exact>
              <Page socket={socket}>
                {/* <Post /> */}
                {/* <Postjob /> */}
                <CreateNewPost />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/servicedeal-detail/:id" exact>
              <Page socket={socket}>
                <ClientServiceDealDetails />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/printdeal-detail" exact>
              <Page socket={socket}>
                <ClientPrintDealDetails />
              </Page>
            </PrivateRoute>

            <PrivateRoute path="/direct-deal/:providerID" exact>
              <Page socket={socket}>
                <PostDirect />
              </Page>
            </PrivateRoute>
            <Route path="/job-details/:jobID" exact>
              <Page socket={socket}>
                {/* <JobDetails /> */}
                <JobDetailsNew />
              </Page>
            </Route>
            <PrivateRoute
              path="/deleted-job/:jobID"
              exact
              render={() => (
                <Page socket={socket}>
                  <JobDetails deleted />
                </Page>
              )}
            />
            <PrivateRoute
              path="/my-post/:jobID"
              exact
              render={() => (
                <Page socket={socket}>
                  <JobDetails mypost />
                </Page>
              )}
            />
            <PrivateRoute path="/approve-job/:jobID" exact>
              <Page socket={socket}>
                <ApproveJobNew />
              </Page>
            </PrivateRoute>
            <Route path="/profile/:userID" exact>
              <Page socket={socket}>
                {/* <UserDetails /> */}
                <ProfileOther />
              </Page>
            </Route>
            <PrivateRoute path="/edit-post/:jobID" exact>
              <Page socket={socket}>
                <EditJob />
              </Page>
            </PrivateRoute>
            <Route path="/terms" exact>
              <Page socket={socket}>
                <Terms />
              </Page>
            </Route>
            <Route path="/privacy" exact>
              <Page socket={socket}>
                <Privacy />
              </Page>
            </Route>

            <Route path="/about" exact>
              <Page socket={socket}>
                <About />
              </Page>
            </Route>

            <Route path="/referrals" exact>
              <Page socket={socket}>
                <Referrals />
              </Page>
            </Route>

            <Route path="/payment-terms" exact>
              <Page socket={socket}>
                <PaymentTerms />
              </Page>
            </Route>

            <Route path="/email/unsubscribe/:token" component={Unsubscribe} />
            <Route path="/logout" component={Logout} />

            <Route path="/faq" exact>
              <Page socket={socket}>
                <FAQ />
              </Page>
            </Route>

            <PrivateRoute path="/notifications" exact>
              <Page>
                <Notifications />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/unresolved-issues" exact>
              <Page socket={socket}>
                <UnresolvedIssues />
              </Page>
            </PrivateRoute>

            <Route path="/auth/verify_forgot_password/:token" exact>
              <Page socket={socket}>
                <VerifyForgotPass />
              </Page>
            </Route>
            <Route path="/auth/verify_email/:token" exact>
              <Page socket={socket}>
                <VerifyEmail />
              </Page>
            </Route>
            <Route
              path="/messages"
              exact
              render={() => (
                <Page socket={socket}>
                  <Messages socket={socket} />
                </Page>
              )}
            />
            <PrivateRoute path="/system-report" exact>
              <Page socket={socket}>
                <SystemReport />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/deal/:dealID" exact>
              <Page socket={socket}>
                <Deal />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/bid/:bidID" exact>
              <Page socket={socket}>
                <Bid />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/activity-log" exact>
              <Page socket={socket}>
                <Logs />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/failed_deals" exact>
              <Page socket={socket}>
                <AdminFaildeDeals />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/refunded_deals" exact>
              <AdminRefundedDeals />
            </PrivateRoute>
            <PrivateRoute path="/failed_bids" exact>
              <Page socket={socket}>
                <AdminFailedBids />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/ui-config" exact>
              <Page socket={socket}>
                <UiConfiguration />
              </Page>
            </PrivateRoute>
            <PrivateRoute path="/promotions" exact>
              <Page socket={socket}>
                <AdminPromo />
              </Page>
            </PrivateRoute>
            <Route path="/404" exact>
              <Page socket={socket}>
                <NotFound />
              </Page>
            </Route>
            <PrivateRoute path="/deals/payment_successful" exact>
              <Page socket={socket}>
                <PaymentSuccess />
              </Page>
            </PrivateRoute>
            <PrivateRoute exact path="/client-details/:id">
              <Page socket={socket}>
                <DemoPage />
              </Page>
            </PrivateRoute>

            <PrivateRoute exact path="/send-feedback">
              <Page socket={socket}>
                <SendFeedBack />
              </Page>
            </PrivateRoute>
            {/* <Route exact path="/deal-details/:id"> */}
            <PrivateRoute exact path="/deal-details/">
              <Page socket={socket}>
                <DealWithProviderDetails />
              </Page>
            </PrivateRoute>
            
            <Route exact path="/blocked" component={Blocked} />
            <Route exact path="/verify" component={OtpVerification} />
            {/* Admin Routes */}
            {(isAdmin || isManager) && (
              <>
                <Route exact path="/admin">
                  <Admin />
                </Route>
                <Route exact path="/admin/manage-agents">
                  <ManageAgents />
                </Route>
                <Route exact path="/admin/manage-admins">
                  <ManageNewAdmins />
                </Route>
                <Route exact path="/admin/jobs">
                  <UnapprovedJobs />
                </Route>
                <Route exact path="/admin/due-payment" component={DuePayment} />
                <Route
                  exact
                  path="/admin/client-payments"
                  component={ClientPayments}
                />
                <Route
                  exact
                  path="/admin/unresolved-issues"
                  component={UnresolvedIssuesNew}
                />

                <Route exact path="/admin/users" component={Users} />
      
                <Route
                  exact
                  path="/admin/add-promotions"
                  component={AddPromotions}
                />
                <Route exact path="/admin/refers" component={AllReferrals} />
                <Route exact path="/admin/bids" component={FailedBids} />
                <Route exact path="/admin/deals" component={FailedDeals} />
                <Route exact path="/admin/feedbacks" component={Feedbacks} />
                <Route
                  exact
                  path="/admin/global-settings"
                  component={GlobalSettingsNew}
                />
                <Route
                  exact
                  path="/payment_successful"
                  component={PaymentDone}
                />
                <Route
                  exact
                  path="/payment_unsuccessful"
                  component={PaymentFailed}
                />
                <Route
                  exact
                  path="/admin/system-report"
                  component={SystemReportNew}
                />
                <Route exact path="/admin/system-logs" component={SystemLogs} />
                <Route
                  exact
                  path="/admin/job-categories"
                  component={JobCategories}
                />
                <Route
                  exact
                  path="/admin/static-pages"
                  component={StaticPages}
                />
                <Route
                  exact
                  path="/admin/ui-configurations"
                  component={UiConfigurations}
                />
              </>
            )}

            <Route path="*" exact>
              <Page socket={socket}>
                <NotFound />
              </Page>
            </Route>
          </Switch>
          {/* <Footer /> */}
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
